@font-face {
    font-family: Gill Sans MT;
    src: url('/GillSansMt/Gill-Sans-MT-Bold.ttf');
    font-display: fallback;
}

@font-face {
    font-family: Muli;
    src: url('/Muli/Muli-ExtraLight.ttf');
    font-weight: 200;
    font-display: fallback;
}

@font-face {
    font-family: Muli;
    src: url('/Muli/Muli-Light.ttf');
    font-weight: 300;
    font-display: fallback;
}

@font-face {
    font-family: Muli;
    src: url('/Muli/Muli.ttf');
    font-weight: normal;
    font-display: fallback;
}

@font-face {
    font-family: Muli;
    src: url('/Muli/Muli-Bold.ttf');
    font-weight: bold;
    font-display: fallback;
}

@font-face {
    font-family: JustAnotherHand;
    src: url('/JustAnotherHand/JustAnotherHand-Regular.ttf');
    font-weight: normal;
    font-display: fallback;
}

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overscroll-behavior: auto;
    font-family: Muli, sans-serif;
    scroll-padding-top: 121px;
    scroll-behavior: smooth;
    scrollbar-color: var(--color-sand) var(--color-coal);

    @media screen and (max-width: 992px) {
        scroll-padding-top: 100px;
    }
}

body {
    background-color: black;
    padding-top: var(--default-header-height);
}

body > main {
    flex-grow: 1;
    max-width: 100%;
    background-color: white;
}

:root {
    --color-sand: #A39278;
    --color-sand-light: #E4DECE;
    --color-sand-dark: #635746;
    --color-orange: #FBC55D;
    --color-egg-white: #F6F4EF;
    --color-coal: #383631;
    --color-sky: #B6D5DE;
    --color-sky-dark: #6F97A3;
    --color-sky-darker: #395963;
    --color-black: #000;
    --color-white: #fff;
    --color-metal: #B4B4B4;
    --color-grey: #5a5954;
    --color-grey-light: #F7F7F7;
    --color-grey-lighter: #FAFAFA;
    --color-bright-blue: #0072E3;

    --header-font-family: Gill Sans MT, sans-serif;
    --primary-font-family: Muli, sans-serif;

    --default-desktop-padding: 48px;
    --default-mobile-padding: 32px;

    --default-max-width: calc(1320px + var(--default-desktop-padding) * 2);
    --wide-max-width: calc(1560px + var(--default-desktop-padding) * 2);
    --slim-max-width: calc(760px + var(--default-desktop-padding) * 2);

    --default-header-height: 0px;
    --default-mobile-header-height: 70px;

    --link-light: var(--color-sky);
    --link-dark: var(--color-sky-darker);
}

* {
    font-family: var(--primary-font-family);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--header-font-family) !important;
    color: var(--color-coal);
    font-weight: unset;
}

h1 {
    // currently only supported on chromium https://caniuse.com/mdn-css_properties_text-wrap_pretty
    text-wrap: pretty;

    // apparently the same as text-wrap: pretty but for firefox and safari
    text-wrap-style: pretty;
}

h2 {
    font-size: 38px;
}

@media screen and (max-width: 992px) {
    h2 {
        font-size: 28px;
    }
}

p {
    a {
        color: var(--link-dark);
    }

    img {
        max-width: 100%;
    }
}
